<template>
  <div class="content">
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8">
         <vuestic-widget :headerText="'Datos Erroneos'" v-show='status===0'>
          <div class="row">
            <div class="col-md-12">
            <h1>Asegurate de que haz escrito correctamente la URL</h1>
            </div>

          </div>
        </vuestic-widget>
           <vuestic-widget :headerText="'Bienvenido al Evento:'" v-show='status===1'>
          <div class="row">
            <div class="col-md-12">
            <h1>{{name_event}}</h1>
            </div>

          </div>
        </vuestic-widget>
        <vuestic-widget :headerText="'Por Favor indica el código de pulsera - tarjeta'" v-show='status===1'>
          <div class="row">
            <div class="col-md-12">
              <div
                class="form-group with-icon-right"
                :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}"
              >
                <div class="input-group">
                  <input id="ktagcode" name="ktagcode" v-model="ktagcode" v-validate="'required'">
                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                  <label
                    class="control-label"
                    for="ktagcode"
                  >{{'view.customer.fields.name' | translate}}</label>
                  <i class="bar"></i>
                  <small
                    v-show="errors.has('name')"
                    class="help text-danger"
                  >{{ errors.first('name') }}</small>
                </div>
              </div>










          </div>
          </div>
          <div class="row">

           <div class="col-md-6 offset-md-3"> <button
              type="button"
              v-on:click="findMoney"
              class="btn btn-primary btn-sm"
            >Buscar</button></div>

          </div>
          <br>
          <br>
           <vuestic-widget :headerText="'Datos Erroneos'" v-show='response===2'>
          <div class="row">
            <div class="col-md-12">
            <h1>Asegurate de que haz escrito correctamente el codigo de la pulsera</h1>
            </div>

          </div>
        </vuestic-widget>
        <div v-show='response===1'>
          <h3> Estos montos se actualizaron el {{userktag.last_conexion}} , si ha realizado transacciones despues de ese momento, es posible que este monto no concuerde con su saldo real</h3>
              <br>
            <div class="row" >

            <div class="col">
              <div class="card">
                <div class="card-header headercard headerorange">
                  <label>
                    <strong>{{userktag.topup_total}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Total Recarga<br></div>

                    </div>

                  </li>
                </ul>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-header headercard headergreen">
                  <label>
                    <strong>- {{userktag.sales_total}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Total Ventas<br></div>

                    </div>

                  </li>
                </ul>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-header headercard headerorange">
                  <label>
                    <strong>- {{userktag.refund_total}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Total Reembolso<br></div>

                    </div>

                  </li>
                </ul>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-header headercard headergreen">
                  <label>
                    <strong>= {{userktag.server_balance}} USD</strong>
                  </label>
                  <br>
                  <label></label>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <div class="row">
                      <div class="col-md-12 labelanalytic">Saldo Actual<br></div>

                    </div>

                  </li>
                </ul>
              </div>
            </div>



          </div>
        </div>

        </vuestic-widget>
      </div>
      <div class="col-md-2">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
//import TableCustomer from "../tables/TableCustomer/TableCustomer.vue";

import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
export default {
  name: "new-customer",
  components: {
    SpringSpinner,
    // TableCustomer,
    Multiselect
  },
  mounted() {

    // checkbox and radios\
    var slugevent = this.$route.params.slug;
    this.loadNameEvent(slugevent)
      .then(data => {
         if(data.status==='fail')
        {
          this.status=0;
        }
        this.name_event = data.name;
        this.idevent = data.id;
      })
      .catch(data => {
        console.log(data);

        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });


  },
  computed: {
    ...mapState({
    }),
    isSuccessfulNameValid() {
      let isValid = false;
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid;
      }
      return isValid;
    },
    isSuccessEmailValid() {
      let isValid = false;
      if (this.formFields.email) {
        isValid =
          this.formFields.email.validated && this.formFields.email.valid;
      }
      return isValid;
    }
  },
  data() {
    return {
      header: "Nuevo Cliente",
      typecustomeroptions: null,
      code: "",
      name_event:"",
      name: "",
      email: "",
      status:1,
      idevent:"",
      conexionKtag:[],
      ktagcode:'',
        userktag:{
      },
      response:0,
      //Optional Inputs

      //----
      idtypecustomer: "",
      active: 1,
      eventoptions: null
    };
  },
  methods: {
    ...mapActions([
      "loadNameEvent",
      "loadBalanceDetailKtag",
      "addToastMessage",
      "loadKtagsConexion"
    ]),
    backPage: function() {
      this.$router.go(-1);
    },
    findMoney() {
      this.$validator.validate().then(result => {
        if (result) {
     var idktag = this.ktagcode;
    this.loadBalanceDetailKtag(idktag)
      .then(data => {
       // this.response=1;
      console.log(data);
        this.userktag = data;
        if(data.status==="fail")
        {

          this.response=2;
        }
        else{
          this.response=1;
        }
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });



        }
      });


    }
  }
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
<style lang="scss">
  .color-icon-label-table {
    td:first-child {
      width: 1rem;
    }
  }

  .headercard {
    height: 80px;
    color: white;
    font-size: 20px;
    font-weight: 600;
  }

  .headergreen {
    background: rgba(3, 153, 136, 1);
    background: -moz-linear-gradient(left,
      rgba(3, 153, 136, 1) 0%,
      rgba(99, 252, 152, 1) 100%);
    background: -webkit-gradient(left top,
      right top,
      color-stop(0%, rgba(3, 153, 136, 1)),
      color-stop(100%, rgba(99, 252, 152, 1)));
    background: -webkit-linear-gradient(left,
      rgba(3, 153, 136, 1) 0%,
      rgba(99, 252, 152, 1) 100%);
    background: -o-linear-gradient(left,
      rgba(3, 153, 136, 1) 0%,
      rgba(99, 252, 152, 1) 100%);
    background: -ms-linear-gradient(left,
      rgba(3, 153, 136, 1) 0%,
      rgba(99, 252, 152, 1) 100%);
    background: linear-gradient(to right,
      rgba(3, 153, 136, 1) 0%,
      rgba(99, 252, 152, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#039988', endColorstr='#63fc98', GradientType=1);
  }

  .headerorange {
    background: rgba(254, 82, 79, 1);
    background: -moz-linear-gradient(left,
      rgba(254, 82, 79, 1) 0%,
      rgba(249, 207, 37, 1) 100%);
    background: -webkit-gradient(left top,
      right top,
      color-stop(0%, rgba(254, 82, 79, 1)),
      color-stop(100%, rgba(249, 207, 37, 1)));
    background: -webkit-linear-gradient(left,
      rgba(254, 82, 79, 1) 0%,
      rgba(249, 207, 37, 1) 100%);
    background: -o-linear-gradient(left,
      rgba(254, 82, 79, 1) 0%,
      rgba(249, 207, 37, 1) 100%);
    background: -ms-linear-gradient(left,
      rgba(254, 82, 79, 1) 0%,
      rgba(249, 207, 37, 1) 100%);
    background: linear-gradient(to right,
      rgba(254, 82, 79, 1) 0%,
      rgba(249, 207, 37, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fe524f', endColorstr='#f9cf25', GradientType=1);
  }

  .headerpink {
    background: rgba(255, 3, 104, 1);
    background: -moz-linear-gradient(left,
      rgba(255, 3, 104, 1) 0%,
      rgba(255, 149, 151, 1) 100%);
    background: -webkit-gradient(left top,
      right top,
      color-stop(0%, rgba(255, 3, 104, 1)),
      color-stop(100%, rgba(255, 149, 151, 1)));
    background: -webkit-linear-gradient(left,
      rgba(255, 3, 104, 1) 0%,
      rgba(255, 149, 151, 1) 100%);
    background: -o-linear-gradient(left,
      rgba(255, 3, 104, 1) 0%,
      rgba(255, 149, 151, 1) 100%);
    background: -ms-linear-gradient(left,
      rgba(255, 3, 104, 1) 0%,
      rgba(255, 149, 151, 1) 100%);
    background: linear-gradient(to right,
      rgba(255, 3, 104, 1) 0%,
      rgba(255, 149, 151, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0368', endColorstr='#ff9597', GradientType=1);
  }

  .headerpurple {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e570e7+0,c85ec7+47,a849a3+100;Pink+3D+%233 */
    background: #e570e7;
    /* Old browsers */
    background: -moz-linear-gradient(left, #e570e7 0%, #c85ec7 47%, #a849a3 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #e570e7 0%, #c85ec7 47%, #a849a3 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #e570e7 0%, #c85ec7 47%, #a849a3 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e570e7', endColorstr='#a849a3', GradientType=1);
    /* IE6-9 */
  }

  .labelanalytic {
    font-weight: 600;
    font-size: 15px;
  }

</style>
